import $ from 'jquery';
import whatInput from 'what-input';


window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import 'tablesaw/dist/tablesaw.jquery';
import libs from './lib/dependancies';
import './lib/jquery-ui-1.12.1.custom/jquery-ui'; // import jQueryUI
import { relative } from 'path';
window.libs = libs;

$(document).foundation();

libs.AOS.init();

jQuery(function (){

  //chrome vh fix
  // get the viewport height and multiple it by 1% to get a value for a vh unit
  // let vh = window.innerHeight * 0.01;
  // set the value in the --vh custom property to the root of the document
  // document.documentElement.style.setProperty('--vh', `${vh}px`);
  
  //about toggler
  $('.hide-toggler').on( "click", function(){
      var text = $(this).text();
      $(this).text( text == "Lue lisää" ? "Piilota" : "Lue lisää" );
      $(this).prev('.hidden').slideToggle('fast');
  });

  //project info toggler
  $('.project__info').on( "click", function(){
    $(this).children('.project__details').toggleClass('project__details--open');
    $(this).children('.project__details').slideToggle('fast'); 
    $(this).toggleClass('project__info--open');
  });

  //project image main image toggle
  $('.project__image-container').on( "click", function(){
    var text = $(this).children('.project__toggler').text();
    $(this).toggleClass('project__image-container--open');
    $(this).children('.project__images').slideToggle('fast');
    $(this).children('.project__toggler').text( text == "Lisää kuvia" ? "Piilota kuvat" : "Lisää kuvia" );
    
    var isClosed = $(this).hasClass('project__image-container--open');
    if ( isClosed == false ) {
      var scrollTargetID = $(this).prev();
      var scrollTargetTop = $(scrollTargetID).offset().top;
      var scrollTargetOffset = 48; //menu height
    
      $('html, body').animate({
          scrollTop: scrollTargetTop - scrollTargetOffset
      }, 400);
    }
  });

  //CV summary toggle
  $('#cv-summary-button').on( "click", function(){
    var text = $(this).text();
    $(this).text( text == "Näytä tiivistelmä" ? "Näytä kaikki" : "Näytä tiivistelmä" );

    var main = $('.cv-main');

    main.find('p').slideToggle();
    main.find('.cv-item--small').slideToggle('fast');
    main.find('.cv-section--minor').slideToggle('fast');
    main.find('.clients-list').slideToggle('fast');
    main.toggleClass('summary');
    main.toggleClass('full');
  });

  //CV print button
  $( document ).ready(function() {
    $('#cv-ptint-button').on( "click", function()
     {
         window.print();
     });
});


  //menu scrollto
  $('.menu-link').on( "click", function() {
    var scrollTargetID = $(this).data('target');
    var scrollTargetTop = $(scrollTargetID).offset().top;
    var scrollTargetOffset = 48; //menu height
    
    $('html, body').animate({
        scrollTop: scrollTargetTop - scrollTargetOffset
    }, 500);

    //console.log(scrollTargetOffset);
  });

  //fade in effect
  $(window).on("scroll", function(){
      // Check the location of each desired element
      $('.fadein').each( function(i){
          var fadeInOffset = 100;
          var fadeInTrigger = $(this).position().top + fadeInOffset;
          var bottomOfWindow = $(window).scrollTop() + $(window).height();

          if( bottomOfWindow > fadeInTrigger ){   
              $(this).animate({'opacity':'1'}, 1000);
          }
      }); 
  });

  // Event listener for the video play button
  var video = document.getElementById("finest-video");
  var playButton = document.getElementById("finest-video-play");

  playButton.addEventListener("click", function() {
    if (video.paused == true) {
      video.play();
      $(this).toggleClass("hidden-important");
      $('#finest-video').attr("controls", "");
    } else {
      video.pause();
    }
  });
  
});

/* ------------------ */



// SVG Injector
// Elements to inject
var mySVGsToInject = document.querySelectorAll('img.inject-me');

// Options
var injectorOptions = {
  evalScripts: 'once',
  pngFallback: 'assets/png'
};

var afterAllInjectionsFinishedCallback = function (totalSVGsInjected) {
  // Callback after all SVGs are injected
  console.log('We injected ' + totalSVGsInjected + ' SVG(s)!');
};

var perInjectionCallback = function (svg) {
  // Callback after each SVG is injected
  console.log('SVG injected: ' + svg);
};

// create injector configured by options
var injector = new libs.svgInjector(injectorOptions);

// Trigger the injection
injector.inject(
  mySVGsToInject,
  afterAllInjectionsFinishedCallback,
  perInjectionCallback
);

// slick carousel
$(".content-carousel").slick({
  // normal options...
  speed: 5000,
	autoplay: true,
	autoplaySpeed: 0,
	cssEase: 'linear',
  slidesToShow: 5,
	slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
	centerMode: true,
  focusOnSelect: true,
  // the magic
  responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        infinite: true
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        dots: true
      }
    }, {
      breakpoint: 300,
      settings: "unslick" // destroys slick
    }]
});

// tablesaw table plugin
$(function () {
  $(document)
    .foundation()
    .trigger('enhance.tablesaw');
});

var TablesawConfig = {
  swipeHorizontalThreshold: 15
};

// app dashboard toggle
$('[data-app-dashboard-toggle-shrink]').on('click', function(e) {
  e.preventDefault();
  $(this).parents('.app-dashboard').toggleClass('shrink-medium').toggleClass('shrink-large');
});